<template>
    <div>
        <b-row>
            <b-col v-if="eklenecekler.length > 0" cols="12" md="3" class="p-0 p-3" style="height: calc(100vh - 56px);overflow: scroll;">
                <div>
                    <transition-group>
                        <b-list-group-item class="hover" v-for="(konteyner, index) in eklenecekler" :key="index" style="background-color: #FFF;">
                            <b-badge variant="primary" class="hover" @click="idAta(index)">
                                <b-icon-map class="mr-2"></b-icon-map>ID: {{konteyner.kk_id ? konteyner.kk_id : null}}
                            </b-badge>
                            <b-badge class="ml-1">
                                <!-- Order: {{konteyner.order}} -->
                            </b-badge>
                            <b-badge @click="sil(index)" variant="danger" class="hover float-right"><b-icon-trash></b-icon-trash></b-badge>
                            <div>
                                <b-input-group size="sm" class="mt-1">
                                    <b-form-input v-model="konteyner.kk_adres"></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="adresKaydet(index, konteyner.kk_adres)">Kaydet</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </b-list-group-item>
                    </transition-group>
                    <b-button v-if="eklenecekler.length > 0" @click="kaydet" block variant="primary" class="mt-3">Kaydet</b-button>
                    <p v-else>Henüz konteyner eklemediniz!</p>
                </div>
            </b-col>






            <b-col cols="12" :md="eklenecekler.length > 0 ? 9 : 12" class="p-0">
                <gmap-map
                :center="center"
                :zoom="12"
                style="width:100vw;  height: calc(100vh - 56px);"
                :options="{disableDefaultUI:true}"
                @click="tikla">
                <gmap-marker
                    :key="m.kk_id"
                    v-for="(m, index) in markers"
                    :position="m"
                    :icon="m.data.sure <= 0 ? dots.red : dots.green"
                    @click="sil(index)"></gmap-marker>
                </gmap-map>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
    data(){
        return{
            center: {
                lat: 38.4653076,
                lng: 27.3498951
            },
            dots: {
                green: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                red: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
            },
            markers: [],
            eklenecekler: [],
        }
    },

    mounted(){
        this.markers = this.konumlar;
    },

    methods: {
        tikla(e){
            const yeni = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
                data: {
                    sure: 3,
                }
            }
            this.markers.push(yeni);
            this.eklenecekler.push(yeni);
            console.log(yeni)
        },

        addMarker(e){
            console.log(e)
        },

        sil(e){
            this.eklenecekler.splice(e, 1);
            this.markers.splice(e, 1);
            console.log(e)
        },

        idAta(index){
            //confirm("Id degeri girin", index)
            Swal.fire({
                title: 'Konteyner Ekle',
                text: 'Eklenecek konteyner ID girin',
                input: 'number',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCancelButton: true,
                confirmButtonText: 'Ekle',
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    var data = {
                        kk_id: result.value,
                        kk_user: this.user.user_id,
                        kk_lat: this.eklenecekler[index].lat,
                        kk_lng: this.eklenecekler[index].lng,
                        kk_adres: "",
                        kk_gun: 3,
                    }
                    this.eklenecekler[index] = data;
                    this.$store.dispatch("konteynerIsaretle", data).then(r => {
                        this.eklenecekler[index] = data;
                        
                        Swal.fire(
                            r.status ? "Tebrikler" : "Hata Oluştu!",
                            r.message,
                        );
                    });
                }
            });
        },

        kaydet(e){
            console.log(e);
        }
    },

    computed: {
        konumlar(){
            return this.$store.state.konteynerlar.map(c => {
                return {
                    lat: Number(c.kk_lat),
                    lng: Number(c.kk_lng),
                    kk_id: c.kk_id,
                    kk_bolge: c.kk_bolge,
                    order: c.kk_order,
                    info: false,
                    data: c.data
                }
            });
        },
        user(){
            return this.$store.state.user;
        }
    }
}
</script>

<style scoped>
.hover:hover{
    cursor: pointer;
}
</style>